import { Euler, MathUtils, Object3D, Quaternion, Vector3 } from "three";
import { IQuaternion, IVector3 } from "../services/Entities";


export function *objectsOfType<T>(ctrl: { new(...args: []): T }, obj: Object3D) : Iterable<T> {

    if (obj instanceof ctrl)
        yield obj;
    else {
        for (const child of obj.children) {
            for (const res of objectsOfType(ctrl, child))
                yield res;
        }
    }
}

export function parentOrSelf(obj: Object3D, selector: (obj: Object3D) => boolean): Object3D {

    let curObj = obj;

    while (curObj) {
        if (selector(curObj))
            return curObj;

        curObj = curObj.parent;
    }
}


export function getFurniture(obj: Object3D) {
    return parentOrSelf(obj, a => a.userData?.type == "Furniture");
}

export function getRoot(obj: Object3D) {
    return parentOrSelf(obj, a => a.parent == null);
}

export function setWorldPostion(obj: Object3D, newPos: Vector3) {

    newPos = obj.parent.worldToLocal(newPos);

    obj.position.set(newPos.x, newPos.y, newPos.z);
} 

export function rotationVecDeg(euler: Euler) {

    return new Vector3(MathUtils.radToDeg(euler.x), MathUtils.radToDeg(euler.y), MathUtils.radToDeg(euler.z));
} 

export function eulerFromDegVector(vec: IVector3): Euler {

    return new Euler(
        MathUtils.degToRad(vec.x),
        MathUtils.degToRad(vec.y),
        MathUtils.degToRad(vec.z));
}


export function toSimpleVector(value: Vector3) : IVector3 {

    return {
        x: value.x,
        y: value.y,
        z: value.z
    };
} 

export function toQuaternion(value: IQuaternion){
    return new Quaternion(value.x, value.y, value.z, value.w);
}

export function eulerFromUnityQuat(value: IQuaternion) {

    var quat = new Quaternion(-value.x, -value.y, value.z, value.w);

    var euler = new Euler();
    euler.setFromQuaternion(quat);
    return euler;
}

export function toSimpleQuaternion(value: Quaternion): IQuaternion {

    return {
        x: value.x,
        y: value.y,
        z: value.z,
        w: value.w
    };
} 

export function quatDiff(to: Quaternion, from: Quaternion) {
  
    return to.clone().multiply(from.clone().invert());
}

export function quatAdd(start: Quaternion, diff: Quaternion) {
    return diff.clone().multiply(start);
}

export function rotateAxisPoint(obj: Object3D, point: Vector3, axis: Vector3, theta: number, pointIsWorld = false) {

    if (pointIsWorld) 
        obj.parent.localToWorld(obj.position);

    obj.position.sub(point); 
    obj.position.applyAxisAngle(axis, theta); 
    obj.position.add(point); 

    if (pointIsWorld) 
        obj.parent.worldToLocal(obj.position); 

    obj.rotateOnAxis(axis, theta); 
}

export function signedAngle(a: Vector3, b: Vector3, axis: Vector3) {

    const dotProduct = a.dot(b);
    const magnitudeA = a.length();
    const magnitudeB = b.length();

    let angle = Math.acos(dotProduct / (magnitudeA * magnitudeB));


    const crossProduct = new Vector3().crossVectors(a, b);

    if (crossProduct.dot(axis) < 0) 
        angle = -angle; 

    return angle;
}

export function randomUUID() {

    if ("crypto" in window && typeof crypto.randomUUID == "function")
        return crypto.randomUUID();
    else {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
            .replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
    }
}