import { BufferAttribute, BufferGeometry, Color, Group, Mesh, MeshStandardMaterial, Object3D, PlaneGeometry, Quaternion, Vector3 } from "three";
import { CoordinateType, FurnitureGeometry, Guid, IFurnitureObject, IRoomInfo, ISceneObject } from "../services/Entities";
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import { apiClient } from "../services/ApiClient";
import { IProgressHandler } from "../abstraction/IProgressHandler";
import { services } from "../services/Services";
import { getOrBuildNode } from "../nodes/NodeBuilder";
import { INodeProvider } from "../nodes/abstraction/INodeProvider";
import { Node } from "../nodes/Node";
import { designerConnector } from "../services/DesignerConnector";
import { quatDiff, rotateAxisPoint, setWorldPostion, toQuaternion, toSimpleQuaternion, toSimpleVector } from "./Utils";
import { SceneView } from "./SceneView";
import { ISceneObjectContainer } from "./abstraction/ISceneObjectContainer";

export class RoomScene implements INodeProvider<RoomScene>, ISceneObjectContainer {

    protected _state: IRoomInfo;
    protected _view: Group;
    protected _room: Group;
    protected _loader: GLTFLoader;
    protected _furnitureNode: Node<Object3D[]>;


    constructor() {
        this._loader = new GLTFLoader();
        this._loader.dracoLoader = new DRACOLoader();
    }


    protected async initFromStateAsync(state: IRoomInfo, onProgress: IProgressHandler = null) {

        console.log(state);

        this._state = state;

        if (!onProgress)
            onProgress = () => false;

        const loader = new GLTFLoader();
        loader.dracoLoader = new DRACOLoader();

        this._view = new Group();

        this._room = new Group();

        this._view.add(this._room);

        onProgress(0, 1, `Loading room`);

        for (const objState of this._state.sceneObjects) {

            let obj: Object3D;

            if (objState.type == "Mesh") {

                const dataUrl = "data:application/octect-stream;base64," + objState.mesh;
                const data = await (await fetch(dataUrl)).arrayBuffer();
                const dataView = new DataView(data, 1);

                const header = dataView.getInt32(0);
                const components = dataView.getInt32(4, true);
                const vcount = dataView.getInt32(8, true);

                const positions = new Float32Array(vcount * 3);

                let curOfs = 12;

                for (let i = 0; i < positions.length; i += 3) {
                    positions[i] = dataView.getFloat32(curOfs, true);
                    positions[i + 1] = dataView.getFloat32(curOfs + 4, true);
                    positions[i + 2] = dataView.getFloat32(curOfs + 8, true);
                    curOfs += 14 * 4;
                }

                const indicesCount = dataView.getInt32(curOfs, true);
                curOfs += 4;

                const indices = new Uint32Array(indicesCount);

                for (let i = 0; i < indices.length; i++) {
                    indices[i] = dataView.getUint32(curOfs, true);
                    curOfs += 4;
                }

                const mesh = new Mesh();
                mesh.geometry = new BufferGeometry();
                mesh.geometry.setAttribute("position", new BufferAttribute(positions, 3))
                mesh.geometry.setIndex(new BufferAttribute(indices, 1));
                mesh.geometry.computeVertexNormals();

                mesh.material = new MeshStandardMaterial({
                    color: new Color(0xa0a0a0)
                });

                mesh.name = "mesh-capture";
                mesh.position.copy(objState.pose.position);
                mesh.quaternion.copy(objState.pose.orientation);

                obj = mesh;
            }
            else if (objState.type == "Floor" || objState.type == "Wall" || objState.type == "Ceiling") {

                const geo = new PlaneGeometry(objState.dimensions.x, objState.dimensions.y);
                const material = new MeshStandardMaterial();

                obj = new Mesh(geo, material);
                obj.position.copy(objState.pose.position);
                obj.quaternion.copy(objState.pose.orientation);

                switch (objState.type) {
                    case "Wall":
                        obj.name = "wall";
                        material.color = new Color("#fff");
                        break;
                    case "Floor":
                        obj.name = "floor";
                        material.color = new Color("#f00");

                        this._room.position.copy(objState.pose.position);
                        this._room.quaternion.copy(objState.pose.orientation);

                        break;
                    case "Ceiling":
                        obj.name = "ceil";
                        material.color = new Color("#0f0");
                        break;
                }

    
            }
            
            if (obj) {
                obj.userData.type = objState.type;
                obj.userData.state = objState;
                this._view.add(obj);
            }
        }


        let loaded = 0;

        const loaders = this._state.furniture.map(furnState => (async () => {

            const obj = await this.addFurnitureAsync(furnState);
            loaded++;
            onProgress(loaded, loaders.length, `Loading ${obj?.name} (${loaded} / ${loaders.length})`);

        })());

        await Promise.all(loaders);

        onProgress(0, 0);
    }

    updateLayout(sceneObjects: ISceneObject[]) {
        
        for (const obj of sceneObjects) {
            const mesh = this._view.children.find(a => (a.userData.state as ISceneObject)?.anchorId == obj.anchorId);

            if (mesh != null) {
                mesh.userData.state = obj;
                mesh.position.copy(obj.pose.position);
                mesh.quaternion.copy(obj.pose.orientation);
                if (obj.type == "Floor") {
                    this._room.position.copy(obj.pose.position);
                    this._room.quaternion.copy(obj.pose.orientation);
                }
            }
        }

        console.log("New layout");
    }

    async center() {
        const floor = this._view.children.find(a => a.userData.type == "Foor");

        this._view.position.set(0, 0, 0);
        this._view.rotation.set(0, 0, 0);

        let wordPos = floor.getWorldPosition(new Vector3());
        setWorldPostion(this._view, wordPos.negate());
        rotateAxisPoint(this._view, new Vector3(0, 0, 0), floor.up, -floor.rotation.z, false);

        SceneView.active.requestRender();
    }

    findFurnitureViewById(id: Guid) {
        return this._room.children.find(a => a.userData.state?.instanceId == id);
    }

    removeFurnitureById(id: Guid) {

        this._room.remove(this.findFurnitureViewById(id));

        this._furnitureNode?.changed.raise({ target: this._furnitureNode, type: "children" });
    }

    async addFurnitureAsync(furnState: IFurnitureObject) {

        const details = await apiClient.catagalogGetDetailsAsync(furnState.modelId);

        if (details.geometry == FurnitureGeometry.Model) {

            const model = await apiClient.catalogOpenResourceAsync(new URL(details.modelUri).pathname.substring(10));

            const glft = await this._loader.parseAsync(model, "/");

            glft.scene.userData.type = "Furniture";
            glft.scene.userData.state = furnState;
            glft.scene.name = details.description;

            this._room.add(glft.scene);

            this.updateFurnitureAsync(furnState, glft.scene);

            this._furnitureNode?.changed.raise({ target: this._furnitureNode, type: "children" });

            return glft.scene;
        }
    }

    getFurnitureFromView(view: Object3D) {

        const result = view.userData.state as IFurnitureObject;

        result.pose = {
            position: toSimpleVector(view.getWorldPosition(new Vector3())),
            orientation: toSimpleQuaternion(view.getWorldQuaternion(new Quaternion()))
        };

        result.coordinateType = CoordinateType.World;

        return result;
    }

    async notifyFurnitureUpdateAsync(view: Object3D) {

        const state = this.getFurnitureFromView(view);

        await designerConnector.updateFurnitureAsync(state);
    }

    async updateFurnitureAsync(value: IFurnitureObject, view?: Object3D) {

        if (!view)
            view = this.findFurnitureViewById(value.instanceId);

        if (value.coordinateType == CoordinateType.World) {
            const pos = new Vector3(value.pose.position.x, value.pose.position.y, value.pose.position.z);
            view.parent.worldToLocal(pos);

            const quat = new Quaternion();
            quat.copy(view.parent.quaternion)
                .invert()
                .multiply(toQuaternion(value.pose.orientation));

            view.position.copy(pos);
            view.quaternion.copy(quat);
        }
        else {
            view.position.copy(value.pose.position);
            view.quaternion.copy(value.pose.orientation);
        }

    }

    static async fromStateAsync(state: IRoomInfo, onProgress: IProgressHandler = null) {
        const result = new RoomScene();
        await result.initFromStateAsync(state, onProgress ?? services.progress);
        return result;
    }

    get view() {
        return this._view;
    }


    node() {
        return getOrBuildNode(this, {
            type: ["RoomScene"],
            components: [{ value: () => this._view }],
            displayName: "room",
            children: [
                {
                    displayName: "layout",
                    value: () => this._view.children.filter(a => a.userData.type != "Furniture"),
                    children: [
                        {
                            displayName: "walls",
                            type: ["Group"],
                            value: () => this._view.children.filter(a => a.userData.type == "Wall"),
                            children: (value: Object3D[]) => value.map(f => ({
                                type: ["3DObject"],
                                value: f
                            }))
                        },
                        {
                            value: () => this._view.children.find(a => a.userData.type == "Floor")
                        },
                        {
                            value: () => this._view.children.find(a => a.userData.type == "Ceiling")
                        },
                        {
                            value: () => this._view.children.find(a => a.userData.type == "Mesh")
                        },
                    ]
                },
                {
                    displayName: "furnitures",
                    value: () => this._room.children.filter(a => a.userData.type == "Furniture"),
                    ref: n => this._furnitureNode = n as any,
                    children: (value: Object3D[]) => value.map(f => ({
                        type: ["Furniture", "3DObject"],
                        value: f
                    }))
                },
            ]
        });
    }
    
}