
export type SceneObjectType = "Floor" | "WINDOW" | "Wall" | "Ceiling" |"Mesh"

export type Base64 = string;

export type Guid = string;

export interface ITransform3 {
    position: IVector3;
    rotationEuler: IVector3;
    forward: IVector3;
    quaternion: IQuaternion;
    localScale: IVector3;
}

export interface IVector3 {
    x: number;
    y: number;
    z: number;
}

export interface IQuaternion {
    x: number;
    y: number;
    z: number;
    w: number;
}


export interface IPose3 {
    orientation: IQuaternion;   
    position: IVector3; 
}


export interface ISceneObject {
    anchorId: Guid;
    type: SceneObjectType;
    pose: IPose3;
    dimensions: IVector3;
    mesh: Base64;

}

export enum CoordinateType {
    None,
    Floor,
    World,
} 

export interface IFurnitureObject {
    anchorId: Guid;
    instanceId: Guid;
    pose: IPose3;
    modelId: string;
    coordinateType: CoordinateType;
}

export interface IRoomInfo {
    roomId: Guid;
    sceneObjects: ISceneObject[];
    furniture: IFurnitureObject[];
}

export interface IPlayerLocation {
    playerId: string;
    head: ITransform3;
}

export interface ITrackingInfo {
    player: IPlayerLocation;
    sceneObjects: ISceneObject[];
}


export enum FurniturePlace {
    Floor,
    Wall
}

export enum FurnitureGeometry {
    Model,
    Picture
}

export interface ISize {
    width: number;
    height: number;
}

export interface IFurnitureDetailsView {
    id: string | null;
    name: string | null;
    description: string | null;
    place: FurniturePlace;
    geometry: FurnitureGeometry;
    pictureSize: ISize | null;
    thumbUri: string | null;
    modelUri: string | null;
    price: number | null;
    rate: number | null;
    rateCount: number | null;
    variantsIds: string[] | null;
}

export interface IOculusLogin {
    code: string;
    org_scoped_id: string;
}

export interface IOculusLoginResult {
    accessToken: string;
    username: string;
    userId: string;
}

export interface IDesignerClient {
    deviceType: string;
    deviceId: string;
}

export interface IFurnitureListFilter {
    offset: number;
    count: number;
    categoryPath: number;
    query: string;
}

export interface IListResult<T>
{
    items: T[];
    count: number;
}

export interface IFurnitureListView {
    id: string;
    name: string;
    description: string;
    thumbUri: string;
}

export enum FurnitureIndexEntryType {
    Group,
    FurnitureGroup,
    Furniture,
}

export interface IFurnitureGroup {
    id: string;
    name: string;
    items: string[];
    groups: IFurnitureGroup[];
}

export interface IFurnitureIndex {
    root: IFurnitureGroup;
    models: Record<string, IFurnitureListView[]>;
}