import { AmbientLight, AxesHelper, Color, GridHelper, PerspectiveCamera, PointLight, Scene, WebGLRenderer, MeshStandardMaterial, ConeGeometry, Euler, MathUtils, Matrix4, Vector2, Raycaster, Group } from 'three';
import { ISceneTool } from './abstraction/ISceneTool';
import { RoomScene } from './RoomScene';
import Stats from 'three/examples/jsm/libs/stats.module.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { SelectionTool } from './tools/SelectionTool';
import { MoveTool } from './tools/MoveTool';
import { OrbitTool } from './tools/OrbitTool';
import { INodeProvider } from '../nodes/abstraction/INodeProvider';
import { NODE, buildNode, getOrBuildNode } from '../nodes/NodeBuilder';
import { AppEvent } from '../helpers/AppEvent';
import { Node } from '../nodes/Node';
import { RotateTool } from './tools/RotateTool';
import { IStateContext, IStateManager } from '../abstraction/IStateManager';
import { SceneDebug } from './SceneDebug';
import { XRButton } from 'three/addons/webxr/XRButton.js';
import { mount } from '@eusoft/webapp-core';
import { VrMain } from '../pages/VrMain';
import { Player } from './Player';


export interface ISceneState {

}

export class SceneView implements INodeProvider, IStateManager<ISceneState> {

    protected _container: HTMLElement;
    protected _resizeObserver: ResizeObserver;

    protected _scene: Scene;
    protected _axes: AxesHelper;
    protected _mainCamera: PerspectiveCamera;
    protected _mainGrid: GridHelper;
    protected _ambientLight: AmbientLight;
    protected _light: PointLight;
    protected _player: Player;
    protected _stats: Stats;

    protected _renderer: WebGLRenderer;
    protected _composer: EffectComposer;

    protected _tools: ISceneTool[];
    protected _roomScene: RoomScene;
    protected _activeTool: ISceneTool;

    protected _selectionTool: SelectionTool;
    protected _raycaster: Raycaster;

    protected _lastRenderTime: number;
    protected _isDirty: boolean;
    protected _isReady: boolean;

    protected _sceneDebug: SceneDebug;

    constructor() {

        this._tools = [];
        this._raycaster = new Raycaster();

        SceneView.active = this;
    }

    attach(element: HTMLElement) {

        this._container = element;

        //Main Scene
        this._scene = new Scene();
        this._scene.background = new Color("#222");


        //Camera
        this._mainCamera = new PerspectiveCamera(50, 0.5, 0.01, 100);
        this._mainCamera.position.set(0, 2, 5);
        this._scene.add(this._mainCamera);

        //Ambient
        this._ambientLight = new AmbientLight(15790320, 0.3);
        this._ambientLight.name = "ambient-light";
        this._scene.add(this._ambientLight);

        //Point
        this._light = new PointLight(15790320, 5, 100);
        this._light.position.set(0, 1.5, 0);
        this._light.name = "point-light";
        this._scene.add(this._light);

        //Grid
        this._mainGrid = new GridHelper(20, 40);
        this._mainGrid.position.y = 0;
        this._mainGrid.material.opacity = 0.25;
        this._mainGrid.material.transparent = true;
        this._scene.add(this._mainGrid);

        //Axes
        this._axes = new AxesHelper(5);
        this._scene.add(this._axes);


        //Player 
        this._player = new Player(this);


        //Renderer
        this._renderer = new WebGLRenderer({ antialias: true });
        this._renderer.autoClear = false;
        this._renderer.setPixelRatio(window.devicePixelRatio);


        this._container.appendChild(this._renderer.domElement);

        //xr
        this._renderer.xr.enabled = true;

        const vrDom = document.createElement("div");
        mount(vrDom, new VrMain());

        document.body.appendChild(XRButton.createButton(this._renderer, {
   
            requiredFeatures: [],
            optionalFeatures: []
        }));

        //Debug
        this._sceneDebug = new SceneDebug(this);


        //Stats
        this._stats = new Stats();
        this._stats.dom.classList.add("stats");
        document.body.appendChild(this._stats.dom);


        //Composer
        this._composer = new EffectComposer(this._renderer);
        const renderPass = new RenderPass(this._scene, this._mainCamera);
        this._composer.addPass(renderPass);


        //Resizer
        this._resizeObserver = new ResizeObserver(() => {
            this.resize();
        });

        this._resizeObserver.observe(this._container);
        this.resize();

        document.addEventListener("visibilitychange", ev => {
            if (document.visibilityState === "visible")
                this.requestRender();
        });
    
        //Tools
        this.addTool(new SelectionTool());
        this.addTool(new MoveTool());
        this.addTool(new RotateTool());
        this.addTool(new OrbitTool());

        this._selectionTool = this.tool(SelectionTool);


        this.renderLoop();
        //this.renderWithFrame();

        this.ready.raise();


        this._isReady = true;
    }

    loadRoom(scene: RoomScene) {

        if (this._roomScene)
            this._scene.remove(this._roomScene.view);

        this._roomScene = scene;
        this._scene.add(scene.view);
        this.requestRender();

        this.node().changed.raise({ target: this.node(), type: "children" });
    }


    update() {
        for (const tool of this._tools) {
            if (tool.isActive && tool.update)
                tool.update();
        }
    }


    addTool(tool: ISceneTool) {
        this._tools.push(tool);
        tool.attach(this);
    }

    tool<T>(ctr: { new(...args: []): T })  {
        return this._tools.find(a => a instanceof ctr) as T;
    }

    player(id: string) {
        return this._player;
    }

    raycast(ev: PointerEvent|MouseEvent) {

        const pos = new Vector2();

        const target = ev.currentTarget as HTMLElement;

        pos.x = ((ev.clientX - target.offsetLeft) / target.clientWidth) * 2 - 1;
        pos.y = - ((ev.clientY - target.offsetTop) / target.clientHeight) * 2 + 1;

        this._raycaster.setFromCamera(pos, this._mainCamera);

        return this._raycaster;
    }

    node() {
        return getOrBuildNode(this, {
            displayName: "scene",
            type: ["Scene", "3DObject"],
            value: () => this._scene,
            children: () => [
                this._roomScene,
                {
                    type: ["Lights", "Group"],
                    displayName: "lights",
                    children: [
                        {
                            value: this._ambientLight,
                        },
                        {
                            value: this._light,
                        }
                    ]
                },
                {
                    type: ["Players", "Group"],
                    displayName: "players",
                    children: () => [this._player]
                }
            ]
        });
    }

    requestRender() {
        this._isDirty = true;
    }

    getState(ctx: IStateContext): ISceneState {
        throw new Error('Method not implemented.');
    }

    setState(state: ISceneState, ctx: IStateContext): void {
        throw new Error('Method not implemented.');
    }

    protected renderLoop() {
        this._renderer.setAnimationLoop(() => {
            this.doRender();
        });
    }

    protected renderWithFrame() {

        requestAnimationFrame(() => {

            this.doRender();

            this.renderWithFrame();

        });
    }

    protected doRender() {

        const now = new Date().getTime();

        this.update();

        this._isDirty = true;

        if (this._isDirty && document.visibilityState == "visible" && (!this._lastRenderTime || !this.maxFrameRate || now - this._lastRenderTime >= 1000 / this.maxFrameRate)) {

            this._renderer.clear();

            for (const tool of this._tools) {
                if (tool.isActive && tool.onPreRender)
                    tool.onPreRender(this._renderer, this._mainCamera);
            }

            this._renderer.render(this._scene, this._mainCamera);

            for (const tool of this._tools) {
                if (tool.isActive && tool.onPostRender)
                    tool.onPostRender(this._renderer, this._mainCamera);
            }


            //this._composer.render();

            this._lastRenderTime = now;


            this._isDirty = false;

            this._stats.update();
        }
    }

    protected resize() {

        const size = new Vector2(this._container.clientWidth, this._container.clientHeight);

        const aspect = size.x / size.y;

        this._mainCamera.aspect = aspect;
        this._mainCamera.updateProjectionMatrix();

        this._renderer.setSize(size.x, size.y);
        this._composer.setSize(size.x, size.y);

        for (const tool of this._tools) {
            if (tool.onResize)
                tool.onResize(size, aspect);
        }

        this.requestRender()
    }

    get debug() {
        return this._sceneDebug;
    }

    get scene() {
        return this._scene;
    }

    get selection() {
        return this._selectionTool;
    }

    get mainCamera() {
        return this._mainCamera;
    }

    get renderer() {
        return this._renderer;
    }

    get room() {
        return this._roomScene;
    }

    get container() {
        return this._container;
    }

    get isReady() {
        return this._isReady;
    }

    set activeTool(value: ISceneTool) {
        this._activeTool = value;
        this.update();
    }

    get activeTool() {
        return this._activeTool;
    }


    maxFrameRate: number = 0;

    readonly ready = new AppEvent();

    static active: SceneView;
}
