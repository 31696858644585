import { Color, ConeGeometry,  Matrix4, Mesh, MeshStandardMaterial, Quaternion, Vector3 } from "three";
import { ISceneObjectContainer } from "./abstraction/ISceneObjectContainer";
import { SceneView } from "./SceneView";
import { ITransform3 } from "../services/Entities";
import { getOrBuildNode } from "../nodes/NodeBuilder";
import { INodeProvider } from "../nodes/abstraction/INodeProvider";

export class Player implements ISceneObjectContainer, INodeProvider<Player> {


    protected _view: Mesh;
    protected _sceneView: SceneView;

    constructor(sceneView: SceneView) {

        this._view = new Mesh(new ConeGeometry(0.08, 0.1));

        const matrix = new Matrix4().makeTranslation(0, 0, -0.05)
            .multiply(new Matrix4().makeRotationY(Math.PI))
            .multiply(new Matrix4().makeRotationX(Math.PI / 2));

        this._view.geometry.applyMatrix4(matrix);

        this._view.material = new MeshStandardMaterial({
            color: new Color(0x2196F3),
            transparent: true,
            opacity: 0.4
        });

        this._sceneView = sceneView;
        this._sceneView.scene.add(this._view);
    }

    update(head: ITransform3) {
        this._view.position.copy(head.position);
        this._view.quaternion.copy(head.quaternion);
        this._view.applyQuaternion(new Quaternion().setFromAxisAngle(new Vector3(0, 1, 0), Math.PI));

        if (this.attachToCamera) {
            this._sceneView.mainCamera.position.copy(head.position);
            this._sceneView.mainCamera.quaternion.copy(head.quaternion);
        }

        this._sceneView.requestRender();
    }

    node() {
        return getOrBuildNode(this, {
            type: ["Player"],
            displayName: "current-player",
            components: [{ value: this._view }]
        });
    }

    get view() {
        return this._view;
    }

    attachToCamera: boolean;
}