import { nodeManager } from "../NodeManager";
import { INodeHandler } from "../abstraction/INodeHandler";
import { Node } from "../Node";
import { buildProps } from "../../helpers/EditorPropertiesBuilder";
import { RoomScene } from "../../view/RoomScene";

export class RoomSceneHandler implements INodeHandler<RoomScene> {

    canHandle(node: Node<RoomScene>): boolean {
        return node.value instanceof RoomScene;
    }


    properties(node: Node<RoomScene>) {

        return buildProps(node, bld => bld
            .float("rotate", prop => prop
                .value(() => 0,
                    v => node.value.center())
                .set({step: 0.05})
            )); 
    }

    readonly priority = 1;

}


nodeManager.register(new RoomSceneHandler());