import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { Guid, IDesignerClient, IFurnitureObject, IPlayerLocation, IRoomInfo, ITrackingInfo } from "./Entities";
import { appSettings } from "./AppSettings";
import { SceneView } from '../view/SceneView';
import { RoomScene } from "../view/RoomScene";

class DesignerConnector {

    protected _connection: HubConnection;
    protected _isStarted = false;

    async stopAsync() {

        this._isStarted = false;

        if (this._connection) {
            await this._connection.stop();
            this._connection = null;
        }
    }

    async startAsync(accessToken: string) {

        await this.stopAsync();

        this._isStarted = true;

        this._connection = new HubConnectionBuilder()
            .withUrl(appSettings.baseUrl + "/hub/design", {
                accessTokenFactory: () => {
                    return accessToken;
                }
            })
            .configureLogging(LogLevel.Information)
            .build();


        const startWorkAsync = async () => {
            try {
                await this._connection.start();
                console.log("SignalR Connected.");
            } catch (err) {
                console.log(err);
                setTimeout(startWorkAsync, 5000);
            }
        }
  
        this._connection.onclose(async () => {
            if (!this._isStarted)
                return;
            await startWorkAsync();
        });

        this._connection.on("CreateRoom", async (room: IRoomInfo) => {

            SceneView.active.loadRoom(await RoomScene.fromStateAsync(room));
        });

        this._connection.on("UpdateTracking", async (track: ITrackingInfo) => {

            SceneView.active.player(track.player.playerId).update(track.player.head);

            if (track.sceneObjects && SceneView.active.room)
                await SceneView.active.room.updateLayout(track.sceneObjects);
        });

        this._connection.on("AddFurniture", async (obj: IFurnitureObject) => {

            await SceneView.active.room?.addFurnitureAsync(obj);
        });

        this._connection.on("UpdateFurniture", async (obj: IFurnitureObject) => {

            await SceneView.active.room?.updateFurnitureAsync(obj);
        });

        this._connection.on("RemoveFurniture", async (id: Guid) => {

            await SceneView.active.room?.removeFurnitureById(id);
        });

        this._connection.on("ClientConnected", async (client: IDesignerClient) => {
            console.log("Connected", client);
        });

        this._connection.on("ClientDisconnected", async (client: IDesignerClient) => {
            console.log("Disconnected", client);
        });

        await startWorkAsync();
    }

    listClientsAsync() {

        if (!this._connection)
            return;
        return this._connection.invoke<IDesignerClient[]>("ListClients");
    }

    registerClientAsync(client: IDesignerClient) {
        if (!this._connection)
            return;
        return this._connection.invoke("RegisterClient", client)
    }

    requestActiveRoomAsync() {
        if (!this._connection)
            return;
        return this._connection.invoke("RequestActiveRoom");
    }

    removeFurnitureAsync(id: Guid) {
        if (!this._connection)
            return;
        return this._connection.invoke("RemoveFurniture", id);
    }

    addFurnitureAsync(obj: IFurnitureObject) {
        if (!this._connection)
            return;
        return this._connection.invoke("AddFurniture", obj);
    }

    updateFurnitureAsync(obj: IFurnitureObject) {
        if (!this._connection)
            return;
        return this._connection.invoke("UpdateFurniture", obj);
    }

    getClientsAsync() {
        if (!this._connection)
            return;
        return this._connection.invoke<IDesignerClient[]>("GetClients");
    }

}

export const designerConnector = new DesignerConnector();