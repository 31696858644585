import { Euler, Object3D, Plane, Quaternion, Vector3 } from "three";
import { BaseSceneTool } from "./BaseSceneTool";
import { SceneView } from "../SceneView";
import { signedAngle } from "../Utils";


export const ROTATE_TOOL = "Rotate";

export class RotateTool extends BaseSceneTool {

    protected _activeObject: Object3D;
    protected _plane: Plane;
    protected _startRotation: Euler;
    protected _startInt: Vector3;

    constructor() {
        super();
    }

    override attach(view: SceneView) {
        super.attach(view)

        this._sceneView.container.addEventListener("pointermove", ev => this.onPointerMove(ev))

        this._sceneView.container.addEventListener("pointerdown", ev => this.onPointerDown(ev))

        this._sceneView.container.addEventListener("pointerup", ev => this.onPointerUp(ev))

        this._sceneView.container.addEventListener("mousedown", ev => this.onPointerDown(ev))

    }

    protected onPointerDown(ev: MouseEvent|PointerEvent) {

        if (!this.isActive || this._sceneView.selection.selectedObjects.length == 0 || ev.button != 2)
            return;

        const sel = this._sceneView.selection.selectedObjects[0];
        const raycaster = this._sceneView.raycast(ev);

        const intersect = raycaster.intersectObject(sel)[0];
        if (!intersect)
            return;

        this._sceneView.activeTool = this;

        this._activeObject = sel;

        const floor = this._sceneView.room.view.getObjectByName("floor");
        const yUp = (new Vector3(0, 0, 1).transformDirection(floor.matrixWorld).sub(
                     new Vector3(0, 0, 0).transformDirection(floor.matrixWorld))).normalize();
        this._plane = new Plane(yUp, -intersect.point.y);

        this._startInt = raycaster.ray.intersectPlane(this._plane, new Vector3());
        this._startRotation = this._activeObject.rotation.clone();

        if ((ev as PointerEvent).pointerId)
            (ev.currentTarget as HTMLElement).setPointerCapture((ev as PointerEvent).pointerId);

        ev.preventDefault();
        ev.stopPropagation();
        ev.stopImmediatePropagation();
    }



    protected onPointerMove(ev: PointerEvent) {

        if (!this._activeObject)
            return;

        const raycaster = this._sceneView.raycast(ev);

        const newInt = new Vector3();

        this._sceneView.debug.clear();

        if (raycaster.ray.intersectPlane(this._plane, newInt)) {
            const objPos = this._activeObject.localToWorld(new Vector3(0, 0, 0)).setY(-this._plane.constant);
            let startVect = this._startInt.clone().setY(-this._plane.constant);
            let curVect = newInt.setY(-this._plane.constant);

            this._sceneView.debug.drawLine(objPos, this._startInt, "#FF0000", "start");
            this._sceneView.debug.drawLine(objPos, newInt, "#0000FF", "cur");

            const from = this._activeObject.parent.worldToLocal(startVect.clone()).sub(this._activeObject.parent.worldToLocal(objPos.clone())).normalize();
            const to = this._activeObject.parent.worldToLocal(curVect.clone()).sub(this._activeObject.parent.worldToLocal(objPos.clone())).normalize();

            var qrot = new Quaternion();
            qrot.setFromUnitVectors(from, to);

            this._activeObject.setRotationFromEuler(this._startRotation);
            this._activeObject.applyQuaternion(qrot);


            this._sceneView.requestRender();

            this._sceneView.room.notifyFurnitureUpdateAsync(this._activeObject);
        }


        ev.preventDefault();
        ev.stopPropagation();
        ev.stopImmediatePropagation();
    }

    protected onPointerUp(ev: PointerEvent) {

        if (!this._activeObject)
            return;


        (ev.currentTarget as HTMLElement).releasePointerCapture(ev.pointerId);

        this._activeObject = null;

        this._sceneView.activeTool = undefined;
    }

    update() {

    }
    

    readonly name = ROTATE_TOOL;
}    